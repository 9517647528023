import React, {useEffect} from 'react';
import {Col, Container, Input, Row} from 'reactstrap';
import classNames from 'classnames';
import {Link} from 'react-scroll';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import track from 'utils/track';
import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section, {SectionImage} from 'components/Elements/Section';
import Video from 'components/Video';
import CountdownTimer from 'components/Elements/CountdownTimer';
import CheckoutButton from 'components/CheckoutButton';
import {AccSales47, AccSales97, SalesDisclaimer} from 'components/SalesCta';
import Footer from 'components/Elements/Footer';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {faExclamationTriangle, faQuestionCircle, faTimes} from '@fortawesome/pro-light-svg-icons';
import {ImgBulletCard, ImgCard} from 'components/Elements/ImgBullet';
import {collection} from 'data/thc.json';
import {CollectionRecipes, CollectionSpeaker} from 'components/Thc/CheckoutSelection';
import GuaranteeBox from 'components/Guarantee';
import FaqList from 'components/FaqList';
import {faqACC, faqACCad} from 'data/faq.json';
import ModalExitCancer from 'components/Modals/ModalExitCancer';

export default function CancerSalesIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = '',
  ctaNoTimer = false,
  variant = null,
  pageTitle = 'Get the Anticancer Collection today!',
  pageDescription = 'Eat the right anticancer foods for a lifetime of excellent health. Get unlimited access to interviews with cancer experts, health-promoting recipes, and more!',
  shareUrl = 'https://health.foodrevolution.org/cancer/join/',
  shareImg = 'https://cdn.foodrevolution.org/thc/og/b-acc-evergreen-opengraph-1200x1200-v20220721.png',
  facebookDescription = 'What are the top superfoods to stay cancer-free? There IS an answer, and it’s 100% natural. Discover the best food and lifestyle tips. Avoid the common carcinogens. Keep your family safe. Learn more here:',
  twitterDescription = 'What are the top superfoods to stay cancer-free? There IS an answer, and it’s 100% natural. Discover the best food and lifestyle tips. Avoid the common carcinogens. Keep your family safe. Learn more here:',
  twitterImg = 'https://cdn.foodrevolution.org/thc/og/b-acc-evergreen-opengraph-1200x675-v20220721.png',
  pinterestDescription = 'What are the top superfoods to stay cancer-free? There IS an answer, and it’s 100% natural. Discover the best food and lifestyle tips. Avoid the common carcinogens. Keep your family safe. Learn more here:',
  pinterestImage = 'https://cdn.foodrevolution.org/thc/og/b-acc-evergreen-opengraph-1000x1500-v20220721.png',
  ...props
}) {
  const {
    phases: {
      cancer: cancerSchedule = {
        phase: 'before',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  const salesFullPrice = !ctaOverride || ctaOverride === 'fullPrice';
  const salesEnd = new Date(cancerSchedule.salesEnd).getTime();

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'B-ACC',
      sku: 'B-ACC',
      name: 'Anti-Cancer Collection',
      variant: !salesFullPrice ? 'acc-special' : 'acc',
      value: !salesFullPrice ? 47 : 97,
      currency: 'usd'
    });
  }, [salesFullPrice]);

  function CancerCheckout({
    iconColor = 'green',
    scrollTo = 'bhc-cta',
    btnText = 'Start Now',
    timerClass = null,
    className = null,
    ...props
  }) {
    return (
      <div className={classNames(`checkout-wrap`, className)}>
        <h2 className="section-heading mb-3">
          {!salesFullPrice ? (
            <>
              Right Now Just <del>$97</del> $47
            </>
          ) : (
            <>Right Now $97</>
          )}
        </h2>

        {ctaNoTimer || salesFullPrice ? null : (
          <>
            <div className="cta-timer mb-3">
              <div className="offer-box-timer-text">Act now to save $50. The sale expires in:</div>
              <CountdownTimer className={timerClass} time={salesEnd} />
            </div>
          </>
        )}
        {variant === 'ad' ? (
          <h5 className="mt-0">Special very limited time offer: Get it now for a 50% savings!</h5>
        ) : null}

        <CheckoutButton
          sku="B-ACC"
          price={!salesFullPrice ? '47' : '97'}
          slug={!salesFullPrice ? 'acc-special' : 'acc'}
        >
          Start Now
        </CheckoutButton>
        <SalesDisclaimer iconColor={iconColor} />
      </div>
    );
  }

  function SalesCtaBox(props) {
    return (
      <>
        {!salesFullPrice ? (
          <>
            {ctaNoTimer ? (
              <AccSales47 {...props} btnText="Start Now" />
            ) : (
              <AccSales47 timerText="Your 50% discount expires in:" date={salesEnd} {...props} btnText="Start Now" />
            )}
          </>
        ) : (
          <AccSales97 {...props} btnText="Start Now" />
        )}
      </>
    );
  }

  return (
    <div className="page page-nosocial d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={shareImg} />
      <div className="page-content">
        <Header
          className="background-purple header-wrap"
          logoWhite
          style="dark"
          pageDescription={pageDescription}
          shareUrl={shareUrl}
          shareImg={shareImg}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterImg={twitterImg}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
        />

        <Section id="acc-header" color="black" className="section-angle-first" innerClass="text-white text-center">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">Replace fear with courage.</h2>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <Video
                  url={
                    !salesFullPrice
                      ? 'https://www.youtube.com/watch?v=XlvA5p1otb8'
                      : 'https://www.youtube.com/watch?v=dDHKCFsWQSc'
                  }
                  playing
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="mt-4 mb-3">
                  {variant === 'ad' ? (
                    <>Take Control with the Anti-Cancer Collection.</>
                  ) : (
                    <>
                      Take Control of Your Health <br className="d-none d-xl-block" />
                      with the Anti-Cancer Collection.
                    </>
                  )}
                </h3>
                <p>Make intelligent food and lifestyle choices demonstrated to help slash cancer risk.</p>
                <CancerCheckout iconColor="white" />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="acc-tired"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1304429049.jpg')}
        >
          <h2 className="section-heading">Tired of hearing that…</h2>
          <p>
            Cancer is genetic or inevitable, and there’s nothing <br className="d-none d-xl-block" />
            {variant === 'ad' ? 'that can be done' : 'you can do about it'}.
          </p>
          <p>
            Food and lifestyle don’t matter when it comes to <br className="d-none d-xl-block" />
            prevention or remission.
          </p>
          <p>After a diagnosis, the only options are surgery, radiation, and chemo.</p>
          <p>Everything causes cancer, so there’s no use trying to avoid toxins.</p>
          <p>The science is confusing, and nobody really knows what foods are actually best for preventing cancer.</p>
        </SectionImage>

        <SectionImage
          id="acc-truth"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1183908006.jpg')}
        >
          <h2 className="section-heading">
            This couldn’t be <br className="d-none d-xl-block" />
            further from the truth.
          </h2>
          <p>
            <b>
              The most rigorous scientific research shows that we have the ability to reduce cancer risk significantly.
            </b>
          </p>
          <p>
            But millions of people feel at the mercy of genetics, without realizing that the odds can be shaped in their
            favor.
          </p>
          <p>
            When this happens, we’re robbed of our ability to take charge of our health, and may even be told that toxic
            chemotherapy treatments are the only thing that can help.
          </p>
          <p>
            Meanwhile, Big Pharma and the Medical Industrial Complex continue to fuel and perpetuate the status quo.
          </p>
          <p>
            {variant === 'ad' ? (
              <>Scared or confused about what to eat and do to slash cancer risk naturally? Keep reading.</>
            ) : (
              <>
                If you’re scared or confused about what to eat and do to slash cancer risk naturally, you’re not alone.
              </>
            )}
          </p>
        </SectionImage>

        <SectionImage
          id="acc-choices-1"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1184835694.jpg')}
        >
          <h2 className="section-heading mb-3">
            Every single day we make countless choices that either bring us closer to cancer or{' '}
            <br className="d-none d-xl-block" />
            move us further away.
          </h2>
          <p>
            {variant === 'ad' ? (
              <>
                Living an anti-cancer lifestyle requires taking charge and thinking differently about what food and
                lifestyles are made every day.
              </>
            ) : (
              <>
                Living an anti-cancer lifestyle requires taking charge and thinking differently about what you eat and
                do each day.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                And it’s 100% worth it, because not only can we make choices that studies tell us can help kick cancer,
                but we these same choices can also contribute to total health, including:
              </>
            ) : (
              <>
                And it’s 100% worth it, because not only will you make choices that studies tell us can help kick
                cancer, but you’ll also be moving towards the many benefits of total health, including:
              </>
            )}
          </p>
          <IconList>
            <IconListItem>Keeping cholesterol in a healthy range</IconListItem>
            <IconListItem>Normal triglycerides</IconListItem>
            <IconListItem>Worry-free blood pressure</IconListItem>
            <IconListItem>Maintaining a healthy weight</IconListItem>
            <IconListItem>A healthy heart </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="acc-choices-2"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1319763314.jpg')}
        >
          <IconList>
            <IconListItem>Creak-free joints</IconListItem>
            <IconListItem>Great energy</IconListItem>
            <IconListItem>Deep sleep</IconListItem>
            <IconListItem>Happy moods</IconListItem>
            <IconListItem>Healthy sexual function</IconListItem>
            <IconListItem>A sharp mind</IconListItem>
            <IconListItem>Clean lungs</IconListItem>
            <IconListItem>Regular bowel movements with less bloating and gas</IconListItem>
            <IconListItem>Smooth skin that defies your age</IconListItem>
            <IconListItem>And more</IconListItem>
          </IconList>
        </SectionImage>

        <Section id="acc-studies" color="light-gray">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10">
                <h2 className="section-heading text-center">
                  Discover What Long-Running <br className="d-none d-xl-block" />
                  Clinical Studies Say
                </h2>
                <p className="mb-5">
                  Hundreds of thousands of nutritional science studies are published every year, with mounds of evidence
                  on the value of food for disease prevention and reversal.
                </p>
                <ImgCard img={require('static/backgrounds/1272269821-wide.jpg')}>
                  Up to 90% of cancers are caused by diet, lifestyle, and environmental factors.<sup>1</sup>
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1204586808-wide.jpg')}>
                  In one large study, one group of cancer survivors ate at least five servings of fruits and vegetables
                  every day and had a 50% reduced rate of recurrence after nine years.<sup>2</sup>
                </ImgCard>
                <ImgCard img={require('static/backgrounds/909946486-wide.jpg')}>
                  A study of nearly 500,000 people published in the International Journal of Cancer found that an
                  increase in plant protein intake of only 2% was associated with a 23% decreased cancer risk.
                  <sup>3</sup>
                </ImgCard>
                <ImgCard img={require('static/backgrounds/104822112-wide.jpg')}>
                  Researchers studied 41,387 women, tracking their consumption of 127 foods over a five-year period.
                  Women with the highest amounts of garlic in their diets had a 50% lower risk of certain colon cancers.
                  <sup>4</sup>
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1141704845-wide.jpg')}>
                  Research suggests eating only two medium stalks of celery two to three times a week could reduce the
                  risk of lung cancer by 60%.<sup>5</sup>
                </ImgCard>
                <h3 className="mt-4 text-center">That’s very good news.</h3>
                <p className="text-center">
                  {variant === 'ad' ? (
                    <>It means that it is possible to beat the odds with the RIGHT food, actions, and information.</>
                  ) : (
                    <>
                      It means that it is possible to beat the odds and change <br className="d-none d-xl-block" />
                      your life with the RIGHT food, actions, and information.
                    </>
                  )}
                </p>
                <p className="text-center">
                  {variant === 'ad' ? (
                    <>
                      We’re all busy, trying to do it all and balance the stress of modern life. Thankfully, we’ve done
                      the scientific research and brought together the experts, making it easier than ever to learn what
                      to eat and do to live an anti-cancer life.
                    </>
                  ) : (
                    <>
                      We’re all busy, trying to do it all and balance the stress of modern life. Thankfully, you don’t
                      have to do all the scientific research yourself to learn what to eat and do to live an anti-cancer
                      life.
                    </>
                  )}
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="acc-questions-1"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1337224522.jpg')}
        >
          <h2 className="section-heading">Questions persist like…</h2>
          <IconList iconColor="purple">
            <IconListItem icon={faQuestionCircle}>Is soy healthy or does it cause cancer?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              What’s the best cancer-fighting diet? Paleo, intermittent fasting, keto, vegan, raw?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>Is fruit okay, or is all sugar bad?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              Is epigenetics real? Is it really possible to flip certain genes on or off?
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="acc-questions-2"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/163186090.jpg')}
        >
          <IconList iconColor="purple">
            <IconListItem icon={faQuestionCircle}>Which are the very best anti-carcinogenic foods?</IconListItem>
            <IconListItem icon={faQuestionCircle}>Do I need to eat organic 100% of the time?</IconListItem>
            <IconListItem icon={faQuestionCircle}>What should I never eat?</IconListItem>
            <IconListItem icon={faQuestionCircle}>Are supplements worth it? Which ones?</IconListItem>
            <IconListItem icon={faQuestionCircle}>Should I cut out coffee or alcohol?</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="acc-thrive"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1337522625.jpg')}
        >
          <h3 className="section-heading">The answers are here.</h3>
          <p>
            This information is available right now, which is why we’ve brought together the world’s top anti-cancer
            experts to share what to eat and do to support healthy cells and a thriving life.
          </p>
        </SectionImage>

        <Section id="acc-intro" color="white">
          <Container>
            <Row className="text-center">
              <Col>
                <h3 className="section-heading text-uppercase">
                  {variant === 'ad' ? <>Our Bodies Want to be Well</> : <>Your Body Wants to be Well</>}
                </h3>
                <p>
                  {variant === 'ad' ? (
                    <>They just have to have what they need to thrive, cancer-free.</>
                  ) : (
                    <>Give it what it needs to thrive, cancer-free.</>
                  )}
                </p>
                <p className="mt-4 mb-2">
                  <i>Introducing the</i>
                </p>
                <img
                  className="mb-4"
                  src="https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-color.svg"
                  style={{maxHeight: '75px'}}
                />
                <h2 className="mt-0 mb-4">Anti-Cancer Collection</h2>
                <Row className="justify-content-center">
                  <Col xs="10" lg="7">
                    <img src="https://cdn.foodrevolution.org/thc/acc-product-image.png" />
                  </Col>
                </Row>
                <p>
                  {variant === 'ad' ? (
                    <>The best resource to help activate the human body’s ability to heal, regenerate, and thrive.</>
                  ) : (
                    <>
                      The best resource to help activate your body’s ability to heal and put your health in your own
                      hands — where it belongs.
                    </>
                  )}
                </p>
                <CancerCheckout className="mt-5" btnText="Get Started" timerClass="countdown-dark" />
              </Col>
            </Row>
            <Row className="justify-content-center text-h5 mt-5 pt-5">
              <Col lg="10">
                <ImgCard img={require('static/backgrounds/1187994030-wide.jpg')}>
                  Eat the ultimate anti-cancer diet.
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1050580190-wide.jpg')}>Build a strong immune system.</ImgCard>
                <ImgCard img={require('static/backgrounds/1234218047-wide.jpg')}>
                  Understand what the latest scientific evidence recommends.
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1222788544-wide.jpg')}>
                  Release mental toxicities and emotional stress.
                </ImgCard>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="acc-inside" color="light-gray">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h2 className="section-heading text-center">What’s Inside the Anti-Cancer Collection</h2>
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/thc/acc-speakers.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">4 Interviews With Anti-Cancer Experts</h4>
                    <p>
                      In this one-of-a-kind collection,{variant === 'ad' ? null : ' you'} get exclusive interviews with
                      four leading cancer experts, including <i>New York Times</i> best-selling authors, doctors,
                      surgeons, and a cancer survivor who put his cancer in remission and now dedicates his life to
                      helping others do the same. (Three of the interviews are audio-only, conducted by 2 million-copy
                      bestselling author John Robbins, and the fourth is video and audio and is conducted by Food
                      Revolution Network CEO Ocean Robbins.)
                    </p>
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/thc/acc-transcripts.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">Written Transcripts</h4>
                    <p>
                      {variant === 'ad' ? (
                        <>
                          No need to write everything down — we’ve done the work already. Each interview is fully
                          transcribed, making it easy to read through the material quickly, highlight important
                          sections, and download it to your computer, or print, to revisit any time. (The transcripts
                          even include source links to key studies cited, for the ease of all fact-checkers and
                          deep-divers if you want.)
                        </>
                      ) : (
                        <>
                          No need to write everything down — we’ve done it for you. Each interview is fully transcribed
                          so you can read through the material quickly, highlight important sections, and download it to
                          your computer, or print, to revisit any time. (The transcripts even include source links to
                          key studies cited, so you can check the facts and dive deeper if you want.)
                        </>
                      )}
                    </p>
                  </Col>
                </Row>
                <hr className="my-5" />
                <h4 className="section-heading">6 Anti-Cancer Recipes</h4>
                <p>
                  {variant === 'ad' ? (
                    <>
                      The scientific studies are in — eating the right foods can be one of the best anti-cancer choices
                      that can be made. This crowd-pleasing collection of recipes are quick, easy, and provide maximum
                      nourishment — helping the body starve cancer cells, and to support an immune system that’s
                      equipped for optimal health.
                    </>
                  ) : (
                    <>
                      The scientific studies are in — eating the right foods can be one of the best anti-cancer things
                      you do. This crowd-pleasing collection of recipes are quick, easy, and provide maximum nourishment
                      for your cells - helping your body to starve cancer cells, and to support an immune system that’s
                      equipped to defend you.
                    </>
                  )}
                </p>
                <CollectionRecipes id="acc" className="mt-4" />
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/global/guidebook/eat-to-defeat-cancer-202202.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">
                      <i>Eat to Defeat Cancer: 10 Superfoods That Nourish Every Cell</i> Guidebook
                    </h4>
                    <p>
                      {variant === 'ad' ? (
                        <>
                          How does one avoid becoming another cancer statistic? We’ve put together a list of 10
                          inexpensive superfoods — research shows these foods provide critical minerals, vitamins,
                          antioxidants, flavonoids, polyphenols, and important phytonutrients that are critical for an
                          anti-cancer life. We also go over the top ten foods to AVOID if to keep cancer at bay. And
                          it’s all impeccably documented, with source links for every fact.
                        </>
                      ) : (
                        <>
                          How do you avoid becoming another cancer statistic? We’ve put together a list of 10
                          inexpensive superfoods — research shows these foods provide critical minerals, vitamins,
                          antioxidants, flavonoids, polyphenols, and important phytonutrients that are critical for an
                          anti-cancer life. We also go over the top ten foods to AVOID if you want to keep cancer out of
                          your life. And it’s all impeccably documented, with source links for every fact.
                        </>
                      )}
                    </p>
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/global/guidebook/acc-workbook.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">Anti-Cancer Workbook</h4>
                    <p>
                      {variant === 'ad' ? (
                        <>
                          Learning what to do is the first step — but remembering it is even more important. We designed
                          this comprehensive workbook to help retain and maximize everything inside. Test your
                          knowledge, take notes, and absorb the information quickly.
                        </>
                      ) : (
                        <>
                          Learning what to do is the first step — but remembering it is even more important. We designed
                          this comprehensive workbook to help you retain and maximize everything you learn. Test your
                          knowledge, take notes, and absorb the information quickly as you follow along.
                        </>
                      )}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="acc-benefit"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1322712919.jpg')}
        >
          <h3 className="section-heading">Anti-Cancer, Simplified</h3>
          <IconList>
            <IconListItem>Listen anytime on {variant === 'ad' ? 'a' : 'your'} smartphone or computer</IconListItem>
            <IconListItem>Read the written transcripts</IconListItem>
            <IconListItem>Make delicious, anti-cancer meals</IconListItem>
            <IconListItem>Learn from trusted sources</IconListItem>
            <IconListItem>Unlimited access</IconListItem>
            <IconListItem>Go at your own pace</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="acc-about-1"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1165575988.jpg')}
        >
          <h4 className="section-heading">Get Revealing Insights About…</h4>
          <IconList>
            <IconListItem>Diet and Breast Cancer</IconListItem>
            <IconListItem>Soy and Soy Products</IconListItem>
            <IconListItem>Proven Strategies to Lower Your Risk</IconListItem>
            <IconListItem>Genetics and Cancer Risk</IconListItem>
            <IconListItem>Coffee and Cancer</IconListItem>
            <IconListItem>Empowering Breast Cancer Patients</IconListItem>
            <IconListItem>Sugar and Cancer Cells</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="acc-about-2"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/951679816.jpg')}
        >
          <IconList>
            <IconListItem>The Risks and Benefits of Chemo</IconListItem>
            <IconListItem>The Beat Cancer Mindset</IconListItem>
            <IconListItem>Organic Food and Cancer</IconListItem>
            <IconListItem>The Power of Forgiveness for Healing</IconListItem>
            <IconListItem>The Power of Prevention</IconListItem>
            <IconListItem>Building a Strong Immune System</IconListItem>
            <IconListItem>Most Beneficial Supplements to Consider</IconListItem>
            <IconListItem>Inner Peace, Attitude, and Health</IconListItem>
          </IconList>
        </SectionImage>

        <Section id="acc-teachers" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">Your Teachers…</h2>
                {variant === 'ad' ? <CollectionSpeaker id="accAd" /> : <CollectionSpeaker id="acc" />}
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="acc-cta" color="gradient-indigo-purple-right">
          <Container>
            <Row>
              <Col>
                <SalesCtaBox />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="acc-learn-1"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1144711962.jpg')}
        >
          <h2 className="section-heading">
            {variant === 'ad' ? <>More Of What’s Inside:</> : <>More Of What You’ll Learn:</>}
          </h2>
          <IconList>
            <IconListItem>
              {variant === 'ad' ? (
                <>The six anti-estrogenic foods to eat every single day.</>
              ) : (
                <>The six anti-estrogenic foods to put in your diet every single day.</>
              )}
            </IconListItem>
            <IconListItem>How to repair DNA breakages using a free 10-minute technique.</IconListItem>
            <IconListItem>
              Scientifically shown steps to decrease inflammatory markers in your bloodstream.
            </IconListItem>
            <IconListItem>The three common trends seen in radical remission survivors.</IconListItem>
            <IconListItem>
              {variant === 'ad' ? (
                <>
                  Why what’s being taught in high school biology about genetics was only a partial truth — and how to
                  turn unwanted genes off in 8 weeks or less.
                </>
              ) : (
                <>
                  Why what you might have been taught in high school biology about genetics was only a partial truth —
                  and how to turn unwanted genes off in 8 weeks or less.
                </>
              )}
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="acc-learn-2"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1306145377.jpg')}
        >
          <IconList>
            <IconListItem>
              The shocking truth about what {variant === 'ad' ? 'most doctors' : 'your doctor'} never learned in medical
              school — and why it’s contributing to millions of people being diagnosed with cancer.
            </IconListItem>
            <IconListItem>
              The six lifestyle factors that could slash{variant === 'ad' ? null : ' your'} cancer risk for good.
            </IconListItem>
            <IconListItem>
              The power of forgiveness, mindfulness, and self-talk for kicking off cellular healing.
            </IconListItem>
            <IconListItem>Which spices and herbs are shown to inhibit cancer development.</IconListItem>
            <IconListItem>
              How to transition {variant === 'ad' ? 'the entire family' : 'your family'} to an anti-cancer diet (it even
              works for small kids).
            </IconListItem>
            <IconListItem>And much, much more.</IconListItem>
          </IconList>
        </SectionImage>

        <Section id="bhc-guarantee" color="light-gray">
          <Container>
            <Row>
              <Col>
                <GuaranteeBox
                  title={
                    <>
                      You’re Protected By <br className="d-none d-md-block d-lg-none" />
                      Our 60-Day, Unconditional, <br className="d-none d-md-block d-lg-none" />
                      Money-Back Guarantee.
                    </>
                  }
                >
                  <p>
                    <b>Try it out.</b> If you aren't completely blown away, even if you{' '}
                    <br className="d-none d-sm-block d-md-none" />
                    just don’t like it, just email us at{' '}
                    <a href="mailto:support@foodrevolution.org">support@foodrevolution.org</a>, any time within 60 days
                    after purchase, for a prompt and courteous refund.
                  </p>
                  <p>
                    This means you can even listen to everything, take in every lesson, and{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    make every single mouth-watering recipe, and if it doesn’t rock your world,{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    you get every penny back.
                  </p>
                  <p>
                    That’s how confident we are that you’ll LOVE the <br className="d-none d-sm-block d-lg-none" />
                    Anti-Cancer Collection!
                  </p>
                  <p>
                    PLUS, if for any reason you request a refund, the entire Anti-Cancer Collection{' '}
                    <br className="d-none d-lg-block d-xl-none" />— everything that you’ve downloaded, and everything
                    you’ve learned — is still <br className="d-none d-lg-block d-xl-none" />
                    yours to keep.
                  </p>
                  <p>That’s why we call our guarantee “better than money back.”</p>
                </GuaranteeBox>
              </Col>
            </Row>
          </Container>
        </Section>

        {variant === 'ad' ? null : (
          <SectionImage
            id="acc-foryou"
            color="white"
            imgPosition="left"
            imgUrl={require('static/backgrounds/1286428994.jpg')}
          >
            <h2 className="section-heading">
              Who The Anti-Cancer <br className="d-none d-xl-block" />
              Collection is For
            </h2>
            <p>
              It’s for you if you want to do more than just leave your health <br className="d-none d-xl-block" />
              up to chance...
            </p>
            <p>If you don’t want to take the word of the mainstream medical industry as your only option.</p>
            <p>If you’re open to new ideas that can maximize how good you feel every day.</p>
            <p>If you want to help others in your family and community to be as healthy as possible.</p>
            <p>If you’re ready to stop buying the myth that modern medicine is the only thing that can save us.</p>
            <p>
              If you believe that all-natural vegetables, fruits, seeds, nuts, and grains have the power to impact your
              health in very good ways.
            </p>
          </SectionImage>
        )}

        <SectionImage
          id="acc-protecting"
          color={variant === 'ad' ? 'white' : 'light-gray'}
          imgPosition="right"
          imgUrl={require('static/backgrounds/1333470317.jpg')}
        >
          <h2 className="section-heading">
            {variant === 'ad' ? (
              <>
                All Health <i>IS</i> Worth Protecting
              </>
            ) : (
              <>Your Health Is Worth Protecting</>
            )}
          </h2>
          <p>
            <b>
              Imagine knowing exactly what to eat, based on what <br className="d-none d-xl-block" />
              hard scientific evidence shows us.
            </b>
          </p>
          <p>Ending the confusion — and never falling prey to fads.</p>
          <p>Enjoying the energy to do what’s important to you, with the people you love.</p>
          <p>Cooking meals that deeply nourish every cell.</p>
          {variant === 'ad' ? null : <p>Taking the power into your own hands.</p>}
          <p>
            {variant === 'ad' ? (
              <>Easily putting knowledge into action.</>
            ) : (
              <>Easily putting what you know into action.</>
            )}
          </p>
          <p>
            Living a long, healthy, happy life — without fear
            {variant === 'ad'
              ? null
              : ' that you’re skipping important information that’s vital to your health and wellbeing'}
            .
          </p>
          <p>Helping {variant === 'ad' ? 'the' : 'your'} family make the best food and lifestyle decisions.</p>
        </SectionImage>

        <Section id="bhc-faq" color={variant === 'ad' ? 'light-gray' : 'white'}>
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Frequently Asked Questions</h3>
                {variant === 'ad' ? <FaqList list={faqACCad} /> : <FaqList list={faqACC} />}
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="acc-everyday"
          color={variant === 'ad' ? 'white' : 'light-gray'}
          imgPosition={variant === 'ad' ? 'left' : 'right'}
          imgUrl={require('static/backgrounds/1304429993.jpg')}
        >
          <h2 className="section-heading">
            {variant === 'ad' ? (
              <>
                Eating happens every day. <br className="d-none d-lg-block" />
                So make it count!
              </>
            ) : (
              <>
                You eat every day. <br className="d-none d-sm-block d-md-none d-lg-block" />
                So make it count!
              </>
            )}
          </h2>
          <p>
            Every person has the ability to reduce their cancer risk significantly with natural food and a few easy
            lifestyle shifts.
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                Eating “perfectly” 100% of the time is not required — but when more anti-cancer foods are introduced,
                there’s an amazing difference in energy, sleep, and happiness levels.
              </>
            ) : (
              <>
                You don’t have to eat “perfectly” 100% of the time — but when you start to eat more anti-cancer foods,
                you’ll feel the amazing difference in your energy, sleep, and happiness level.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                Pretty soon, taste buds change and craving kale and broccoli instead of junk food becomes the norm. We
                hear this all the time!
              </>
            ) : (
              <>
                Pretty soon, your taste buds change and you might start craving kale and broccoli instead of junk food.
                We hear this all the time!
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                And for anyone already diagnosed with cancer, it’s critical to find out all the options and hear stories
                of others who’ve put their cancer in remission.
              </>
            ) : (
              <>
                And if you’ve already been diagnosed with cancer, it’s critical to find out all your options and hear
                stories of others who’ve put their cancer in remission.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                The most important thing is to stop living in fear and to give our bodies the natural, powerful,
                anti-cancer foods they love.
              </>
            ) : (
              <>
                The most important thing is to stop living in fear and to give your body the natural, powerful,
                anti-cancer foods it loves.
              </>
            )}
          </p>
        </SectionImage>

        <Section id="acc-cta-2" color="gradient-indigo-purple-right">
          <Container>
            <Row>
              <Col>
                <SalesCtaBox trees />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="acc-resources" color="light-gray" className="section-angle-last" angle="none">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Resources:</h3>

                <ul className="list-references list-group text-small mb-0">
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[1]</div>
                    <div>
                      Anand P, Kunnumakkara AB, Sundaram C, Harikumar KB, Tharakan ST, Lai OS, Sung B, Aggarwal BB.
                      Cancer is a preventable disease that requires major lifestyle changes. <i>Pharm Res.</i> 2008
                      Sep;25(9):2097-116. doi: 10.1007/s11095-008-9661-9.
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[2]</div>
                    <div>
                      Pierce JP, Faerber S, Wright FA, Rock CL, Newman V, Flatt SW, Kealey S, Jones VE, Caan BJ, Gold
                      EB, Haan M, Hollenbach KA, Jones L, Marshall JR, Ritenbaugh C, Stefanick ML, Thomson C, Wasserman
                      L, Natarajan L, Thomas RG, Gilpin EA; Women's Healthy Eating and Living (WHEL) study group. A
                      randomized trial of the effect of a plant-based dietary pattern on additional breast cancer events
                      and survival: the Women's Healthy Eating and Living (WHEL) Study. <i>Control Clin Trials.</i> 2002
                      Dec;23(6):728-56. doi: 10.1016/s0197-2456(02)00241-6.
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[3]</div>
                    <div>
                      Allen NE, Appleby PN, Key TJ, Bueno-de-Mesquita HB, Ros MM, Kiemeney LA, Tjønneland A, et al.
                      Macronutrient intake and risk of urothelial cell carcinoma in the European prospective
                      investigation into cancer and nutrition. <i>Int J Cancer.</i> 2013 Feb 1;132(3):635-44. doi:
                      10.1002/ijc.27643.
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[4]</div>
                    <div>http://www.garlicseedfoundation.info/fall_94/health.htm</div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[5]</div>
                    <div>http://www.aicr.org/reduce-your-cancer-risk/diet/alcohol-and-cancer-risk.html</div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>

      <div className="footer-content mt-auto">
        <Footer />
      </div>
      {noExitIntent ? null : <ModalExitCancer version={!salesFullPrice ? 'salesPrice' : 'fullPrice'} />}
    </div>
  );
}
