import React, {useEffect} from 'react';
import {Col, Container, Input, Row} from 'reactstrap';
import classNames from 'classnames';
import {Link} from 'react-scroll';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import track from 'utils/track';
import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section, {SectionImage} from 'components/Elements/Section';
import Video from 'components/Video';
import CountdownTimer from 'components/Elements/CountdownTimer';
import CheckoutButton from 'components/CheckoutButton';
import {IhcSales47, IhcSales97, SalesDisclaimer} from 'components/SalesCta';
import Footer from 'components/Elements/Footer';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {faExclamationTriangle, faQuestionCircle, faTimes} from '@fortawesome/pro-light-svg-icons';
import {ImgBulletCard, ImgCard} from 'components/Elements/ImgBullet';
import {collection} from 'data/thc.json';
import {CollectionRecipes, CollectionSpeaker} from 'components/Thc/CheckoutSelection';
import GuaranteeBox from 'components/Guarantee';
import FaqList from 'components/FaqList';
import {faqIHC, faqIHCAd} from 'data/faq.json';
import ModalExitImmunity from 'components/Modals/ModalExitImmunity';

export default function ImmunitySalesIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = '',
  ctaNoTimer = false,
  variant = null,
  pageTitle = 'Get the Immune Health Collection today!',
  pageDescription = 'Get unlimited access to interviews with immune health experts, health-promoting recipes, and more!',
  shareUrl = 'https://health.foodrevolution.org/immunity/join/',
  shareImg = 'https://cdn.foodrevolution.org/thc/og/b-ihc-open_graph-1200x1200-v202203.png',
  facebookDescription = 'Discover the natural food and lifestyle choices for a healthy and strong immune system. Get started here:',
  twitterDescription = 'Discover the natural food and lifestyle choices for a healthy and strong immune system. Get started here:',
  twitterImg = 'https://cdn.foodrevolution.org/thc/og/b-ihc-open_graph-1200x630-v202203.png',
  pinterestDescription = 'Discover the natural food and lifestyle choices for a healthy and strong immune system. Get started here:',
  pinterestImage = 'https://cdn.foodrevolution.org/thc/og/b-ihc-open_graph-1000x1500-v202203.png',
  ...props
}) {
  const {
    phases: {
      immunity: immunitySchedule = {
        phase: 'before',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  const salesFullPrice = !ctaOverride || ctaOverride === 'fullPrice';
  const salesEnd = new Date(immunitySchedule.salesEnd).getTime();

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'B-IHC',
      sku: 'B-IHC',
      name: 'Immune Health Collection',
      variant: !salesFullPrice ? 'ihc21-special' : 'ihc21',
      value: !salesFullPrice ? 47 : 97,
      currency: 'usd'
    });
  }, [salesFullPrice]);

  function ImmunityCheckout({iconColor = 'green', timerClass = null, className = null, ...props}) {
    return (
      <div className={classNames(`checkout-wrap`, className)}>
        <h2 className="section-heading mb-3">
          {!salesFullPrice ? (
            <>
              Right Now Just <del>$97</del> $47
            </>
          ) : (
            <>Right Now $97</>
          )}
        </h2>

        {ctaNoTimer || salesFullPrice ? null : (
          <>
            <div className="cta-timer mb-3">
              <div className="offer-box-timer-text">Your 50% discount expires in:</div>
              <CountdownTimer className={timerClass} time={salesEnd} />
            </div>
          </>
        )}
        {variant === 'ad' ? (
          <h5 className="mt-0">Special very limited time offer: Get it now for a 50% savings!</h5>
        ) : null}

        <CheckoutButton
          sku="B-IHC"
          price={!salesFullPrice ? '47' : '97'}
          slug={!salesFullPrice ? 'ihc21-special' : 'ihc21'}
        >
          Start Now
        </CheckoutButton>
        <SalesDisclaimer iconColor={iconColor} />
      </div>
    );
  }

  function SalesCtaBox() {
    return (
      <>
        {!salesFullPrice ? (
          <>
            {ctaNoTimer ? (
              <IhcSales47 btnText="Start Now" />
            ) : (
              <>
                <IhcSales47 timerText="Your 50% discount expires in:" date={salesEnd} btnText="Start Now" />
              </>
            )}
          </>
        ) : (
          <IhcSales97 btnText="Start Now" />
        )}
      </>
    );
  }

  return (
    <div className="page page-nosocial d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={shareImg} />
      <div className="page-content">
        <Header
          className="background-purple header-wrap"
          logoWhite
          style="dark"
          pageDescription={pageDescription}
          shareUrl={shareUrl}
          shareImg={shareImg}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterImg={twitterImg}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
        />

        <Section id="ihc-header" color="black" className="section-angle-first" innerClass="text-white text-center">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">
                  {variant === 'ad' ? (
                    <>
                      Eat the Best Foods <br className="d-none d-sm-block d-lg-none" />
                      for Immune Health <br className="d-none d-lg-block" />
                      with the <br className="d-none d-sm-block d-lg-none" />
                      Immune Health Collection
                    </>
                  ) : (
                    <>
                      Stay safe in the pandemic <br className="d-none d-sm-block d-lg-none" />
                      and beyond <br className="d-none d-lg-block" />
                      with the <br className="d-none d-sm-block d-lg-none" />
                      Immune Health Collection
                    </>
                  )}
                </h2>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <Video
                  url={
                    !salesFullPrice
                      ? 'https://www.youtube.com/watch?v=7XxEo90Xs6c'
                      : 'https://www.youtube.com/watch?v=6pU3g08eYLs'
                  }
                  label="IHC - Sales"
                  playing
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="mt-4 mb-4">
                  {variant === 'ad' ? (
                    <>Discover the proven keys to a supercharged immune system.</>
                  ) : (
                    <>Discover the proven keys to supercharging your immune system.</>
                  )}
                </p>
                <ImmunityCheckout iconColor="white" />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="ihc-sick"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1284370009.jpg')}
        >
          <h2 className="section-heading">
            {variant === 'ad' ? (
              <>
                Sick and tired of <br className="d-none d-sm-block" />
                being sick and tired?
              </>
            ) : (
              <>
                Are you sick and tired of <br className="d-none d-sm-block" />
                being sick and tired?
              </>
            )}
          </h2>
          <p>Worried about colds, flu, and viruses...</p>
          <p>Confused about how to eat for the best immune health…</p>
          <p>
            {variant === 'ad' ? (
              <>Reluctant to take antibiotics that can damage gut health…</>
            ) : (
              <>Having to take antibiotics that destroy your gut health…</>
            )}
          </p>
          <p>Living in fear of being around other people{variant === 'ad' ? null : ', even those you love'}…</p>
          <p>Dreading getting sick because it could take{variant === 'ad' ? null : ' you'} weeks to recover…</p>
          {variant === 'ad' ? null : (
            <p>
              Worried because you have other conditions that put you at{' '}
              <br className="d-none d-sm-block d-md-none d-xl-block" />a higher risk…
            </p>
          )}
          <p>
            {variant === 'ad' ? (
              <>
                Or feeling run down and depleted — and wishing for more{' '}
                <br className="d-none d-sm-block d-lg-none d-xl-block" />
                energy and resilience?{' '}
              </>
            ) : (
              <>
                Or feeling run down and depleted — and wishing you had more{' '}
                <br className="d-none d-sm-block d-lg-none d-xl-block" />
                energy and resilience?
              </>
            )}
          </p>
        </SectionImage>

        <SectionImage
          id="ihc-mercy"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1189299509.jpg')}
        >
          <h2 className="section-heading">
            {variant === 'ad' ? (
              <>
                What do people with <br className="d-none d-xl-block" />a strong immune system{' '}
                <br className="d-none d-xl-block" />
                have in common?
              </>
            ) : (
              <>Release the stress and know you’re doing everything you can for your immune health.</>
            )}
          </h2>
          <p>
            Some people go for decades without even a sniffle, while others are terrified because they could catch
            something and get knocked out for weeks or even months — and with everything that’s happened in the world
            the last few years, none of us can afford to ignore our{' '}
            <br className="d-none d-sm-block d-md-none d-xl-block" />
            immune system.
          </p>
          {variant === 'ad' ? null : (
            <p>
              So what do people with a strong immune system <br className="d-none d-lg-block d-xl-none" />
              have in common?
            </p>
          )}
          <p>
            {variant === 'ad' ? (
              <>What can we do to help our immune system do its job?</>
            ) : (
              <>What can you do to help your immune system do its job?</>
            )}
          </p>
        </SectionImage>

        <SectionImage
          id="ihc-healthy"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1329106643.jpg')}
        >
          {variant === 'ad' ? null : <h2 className="section-heading mb-3">You’re Not Alone.</h2>}
          <h2 className="mt-0">
            We <i>all</i> want to stay healthy…
          </h2>
          <p>
            But very few of us are taught how to keep our immune systems healthy,{' '}
            <b>
              <i>the natural way.</i>
            </b>
          </p>
          <p>
            Most of us {variant === 'ad' ? 'didn’t' : 'don’t'} get specific immune health instructions in school.
            (Sadly, this is true even for most doctors.)
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                To make things worse, most of the websites and blogs claiming to have “answers,” are usually just
                clickbait trying to sell the newest health fad.
              </>
            ) : (
              <>
                To make things worse, most of the websites and blogs claiming to have answers, unfortunately, are really
                just clickbait trying to sell you the newest health fad.
              </>
            )}
          </p>
          <p>
            It can be hard to know who to trust, and what actually makes a difference when it comes to immune health.
          </p>
        </SectionImage>

        <SectionImage
          id="ihc-system"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1305332035.jpg')}
        >
          <h3 className="section-heading">
            Every day we make countless choices that either strengthen our immune systems, or drain them.
          </h3>
          <h4 className="mt-0">The good news?</h4>
          <p>
            Eating the right vegetables, fruits, and other whole foods is the <br className="d-none d-xl-block" />
            #1 way to give {variant === 'ad' ? 'the' : 'your'} immune system what it needs to thrive.
          </p>
        </SectionImage>

        <Section id="ihc-studies" color="light-gray">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10">
                <h2 className="section-heading text-center">
                  What Long-Running <br className="d-none d-sm-block d-xl-none" />
                  Clinical Studies Say
                </h2>
                <p className="text-center mb-4">
                  <b>
                    {variant === 'ad' ? (
                      <>
                        Thousands of nutritional science studies are published every year,{' '}
                        <br className="d-none d-sm-block d-lg-none" />
                        with mounds of evidence showing us what to eat for{' '}
                        <br className="d-none d-sm-block d-lg-none" />
                        immune health. For example...
                      </>
                    ) : (
                      <>
                        Thousands of nutritional science studies are published every year,{' '}
                        <br className="d-none d-md-block d-lg-none" />
                        with mounds of evidence showing us what you need to eat for{' '}
                        <br className="d-none d-md-block d-lg-none" />
                        immune health. For example...
                      </>
                    )}
                  </b>
                </p>
                <ImgCard img={require('static/studies-537403487.jpg')}>
                  Eating kiwi fruit has been shown to reduce the duration of the common cold — often by several days —
                  and it can reduce a child’s risk of getting sick by 50%.<sup>1</sup> Pro tip: Eating the peel gives
                  you even more benefits!
                </ImgCard>
                <ImgCard img={require('static/studies-628986454.jpg')}>
                  Green tea is loaded with compounds called catechins, as well as the antioxidant quercetin and the
                  amino acid L-theanine, all of which support a strong immune system and help the body fight viruses and{' '}
                  {variant === 'ad' ? 'disease' : 'cancers'}. One study showed that women under 50 who drank green tea
                  at least three times per day reduced their risk{variant === 'ad' ? null : ' of breast cancer'} by 37%!
                  <sup>2,3</sup>
                </ImgCard>
                <ImgCard img={require('static/studies-1153932360.jpg')}>
                  Nutritional yeast contains beta-glucans, which have powerful infection-preventing and
                  immunity-supporting properties. In fact, a study published in the European Journal of Nutrition found
                  that people who consumed one tablespoon of nutritional yeast per day were able to reduce recurrence of
                  infections from the common cold by 25%.<sup>4</sup>
                </ImgCard>
                <ImgCard img={require('static/studies-614871876.jpg')}>
                  Apples contain a natural bioactive chemical called ursolic acid that helps to keep our vascular
                  endothelial cells at their peak. This is critical for immune health.<sup>5,6</sup>
                </ImgCard>
                <ImgCard img={require('static/studies-487260146.jpg')}>
                  Onions are a great source of quercetin, which is known to have
                  {variant === 'ad' ? null : ' anti-cancer, anti-inflammatory, and'} anti-viral properties. The highest
                  concentration is in the outer rings.<sup>7,8</sup>
                </ImgCard>
                <ImgCard img={require('static/studies-1309812073.jpg')}>
                  Studies have shown that people with vitamin D deficiency are 11 times more likely to get a cold or flu
                  {variant === 'ad' ? '.' : ' — and there are similar findings with COVID-19 and vitamin D, too!'} The
                  answer? Healthy sun exposure or supplementing with vitamin D3, which has been shown to reduce colds
                  and flu by up to 42%.
                  <sup>9,10</sup>
                </ImgCard>
                <h4 className="text-center">
                  {variant === 'ad' ? (
                    <>
                      That’s very good news. It means that the RIGHT food, actions, <br className="d-none d-xl-block" />
                      and information can make all the difference.
                    </>
                  ) : (
                    <>
                      That’s very good news. It means that with the <br className="d-none d-md-block d-xl-none" />
                      RIGHT food, actions, and information, <br className="d-none d-sm-block d-xl-none" />
                      you can change your life.
                    </>
                  )}
                </h4>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="ihc-questions-1"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/838127738.jpg')}
        >
          <p>Even smart people who avoid junk food aren’t always getting the variety of nutrients they need.</p>
          <h5 className="mt-0">Questions persist, like:</h5>
          <IconList iconColor="purple">
            <IconListItem icon={faQuestionCircle}>How can I increase my body’s natural killer cells?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              {variant === 'ad' ? (
                <>
                  How do we avoid future pandemics and super <br className="d-none d-xl-block" />
                  strains of viruses?
                </>
              ) : (
                <>How do we avoid future pandemics and super strains of viruses and influenza?</>
              )}
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>What should I never eat?</IconListItem>
            <IconListItem icon={faQuestionCircle}>Is fruit okay, or are all sweet things bad?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              Is it true that I should “starve a fever” and “feed a cold”?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>Can I really flip certain genes on or off?</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="ihc-questions-2"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1043179154.jpg')}
        >
          <IconList iconColor="purple">
            <IconListItem icon={faQuestionCircle}>What are inflammatory cytokines, or “cytokine storms”?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              What does the latest research tell us about food and {variant === 'ad' ? 'viruses' : 'COVID-19'}?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>Does the right nutrition really help?</IconListItem>
            <IconListItem icon={faQuestionCircle}>Which are the very best immune health foods?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              {variant === 'ad' ? (
                <>What should I eat if I have existing health conditions?</>
              ) : (
                <>What should I eat if I have diabetes, obesity, and hypertension?</>
              )}
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>Are supplements effective?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              Is it really safe to go plant-based? What about protein, zinc, iron, B-12, and omega-3 fatty acids?
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="ihc-truth"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/923721462.jpg')}
        >
          <h2 className="section-heading">
            The truth is that many of us think we’re eating healthier than we actually are.
          </h2>
          <p>
            <b>While millions are interested in being healthy, we’re not always fed the whole truth.</b>
          </p>
          <IconList iconColor="yellow">
            <IconListItem icon={faExclamationTriangle}>
              Gluten-free products are in every grocery store, but that doesn’t make them healthy. (Many of them contain
              ingredients that can spike blood sugar even more than white flour.)
            </IconListItem>
            <IconListItem icon={faExclamationTriangle}>
              Unhealthy oils sneak into low-carb convenience foods.
            </IconListItem>
            <IconListItem icon={faExclamationTriangle}>
              Food companies still aren’t required to disclose if their ingredients contain genetically modified
              ingredients.
            </IconListItem>
            <IconListItem icon={faExclamationTriangle}>
              Sugar, one of the most addictive substances, masquerades under different names, but still has the same
              effect: depleting the immune system.
            </IconListItem>
          </IconList>
        </SectionImage>

        <Section id="ihc-intro" color="white">
          <Container>
            <Row className="text-center">
              <Col>
                <h3 className="section-heading">
                  {variant === 'ad' ? 'THE' : 'YOUR'} BODY <i>WANTS</i> TO BE WELL.
                </h3>
                <p>
                  We know the foods and daily practices that can help{variant === 'ad' ? null : ' you'} create a
                  rock-solid immune system.
                </p>
                <p>
                  That’s why we’ve brought together the world’s top immune health experts{' '}
                  <br className="d-none d-xl-block" />
                  to {variant === 'ad' ? 'share' : 'teach you'} what to eat and do for a strong immune system.
                </p>
                <p className="mt-5">
                  <i>Introducing the</i>
                </p>
                <img
                  className="mb-1"
                  src="https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-color.svg"
                  style={{maxHeight: '75px'}}
                />
                <h2 className="mt-0">Immune Health Collection</h2>
                <Row className="justify-content-center">
                  <Col xs="10" lg="7">
                    <img src="https://cdn.foodrevolution.org/thc/ihc21-product-image.png" />
                  </Col>
                </Row>
                {variant === 'ad' ? null : (
                  <p>Everything you need to put your health in your own hands — where it belongs.</p>
                )}
                <ImmunityCheckout className="mt-5" btnText="Get Started" timerClass="countdown-dark" />
              </Col>
            </Row>
            <Row className="justify-content-center text-h5 mt-5 pt-5">
              <Col lg="10">
                <ImgCard img={require('static/intro-1282671473.jpg')}>Eat the ultimate immune health diet.</ImgCard>
                <ImgCard img={require('static/intro-1044768344.jpg')}>
                  {variant === 'ad' ? (
                    <>Avoid foods and habits that deplete immune health.</>
                  ) : (
                    <>Avoid foods and habits that make you vulnerable.</>
                  )}
                </ImgCard>
                <ImgCard img={require('static/intro-836090694.jpg')}>
                  Understand the latest scientific research.
                </ImgCard>
                <ImgCard img={require('static/intro-1153385188.jpg')}>
                  {variant === 'ad' ? (
                    <>Enjoy delicious immune-healthy meals.</>
                  ) : (
                    <>Feed your cells what they need to protect you.</>
                  )}
                </ImgCard>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="ihc-inside" color="light-gray">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10">
                <h2 className="section-heading text-center">
                  What’s Inside the <br className="d-none d-xl-block" />
                  Immune Health Collection
                </h2>
                <h4>Four Interviews With Immune Health Experts</h4>
                <p>
                  {variant === 'ad' ? 'This' : 'In this'} one-of-a-kind collection
                  {variant === 'ad' ? ' provides' : ', you get'} exclusive interviews (and transcripts) with four
                  leading immune health experts, including <i>New York Times</i> best-selling authors, doctors,
                  nutrition scientists, respected researchers, and TED Talk presenters with over 11 million views.
                  (Three of the interviews are audio-only, conducted by 2 million-copy bestselling author John Robbins,
                  and the fourth is video and audio and is conducted by Food Revolution Network CEO Ocean Robbins.)
                </p>

                <h4>Written Transcripts</h4>
                <p>
                  Love to read? Each interview is fully transcribed
                  {variant === 'ad' ? ', making it easy to' : ' so you can'} read through the material quickly,{' '}
                  <br className="d-none d-xl-block" />
                  highlight important sections, and download to {variant === 'ad' ? 'a' : 'your'} computer to revisit
                  anytime.
                </p>

                <h4>Six Immune Health Recipes</h4>
                <p>
                  The scientific studies are in, and it’s very good news! Eating the right foods is one of the best
                  things {variant === 'ad' ? 'for the' : 'you can do for your'} immune system. The “easy gourmet”
                  recipes in this immune-supporting collection are quick, easy, and provide maximum nutrients for{' '}
                  <i>all</i>
                  {variant === 'ad' ? null : ' your'} cells.
                </p>
                <CollectionRecipes id="ihc" className="mt-4" />

                <h4>
                  {variant === 'ad' ? (
                    <>
                      <i>Smart Immunity: Diet and Lifestyle</i> Guidebook
                    </>
                  ) : (
                    <>
                      <i>
                        Smart Immunity: How Diet and Lifestyle Can Help You Stay Healthy{' '}
                        <br className="d-none d-xl-block" />
                        in the Time of COVID-19
                      </i>{' '}
                      Guidebook
                    </>
                  )}
                </h4>
                <p>
                  {variant === 'ad' ? (
                    <>
                      From specific foods and tips to supplements that are scientifically shown to work, the{' '}
                      <i>Smart Immunity</i> guide includes the top things to stay safe and healthy.
                    </>
                  ) : (
                    <>
                      Is your immune system leaving you susceptible to colds, viruses, and disease, or is it
                      overreacting with an autoimmune disorder? What you need is a smart immune system. From specific
                      foods and tips you might be skipping to supplements that are scientifically shown to work, the{' '}
                      <i>Smart Immunity</i> guide will teach you the top things you can do to keep you and your family
                      safe.
                    </>
                  )}
                </p>

                <h4>Immune Health Workbook</h4>
                <p>
                  {variant === 'ad' ? (
                    <>
                      Learning what to do is the first step — but remembering it is even more important. We designed
                      this comprehensive workbook to be the perfect companion to the immune health lessons — without
                      information overload.
                    </>
                  ) : (
                    <>
                      Learning what to do is the first step — but remembering it is even more important. We designed
                      this comprehensive workbook to help you retain and maximize everything you learn. Test your
                      knowledge, take notes, and absorb the information quickly as you follow along.
                    </>
                  )}
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="ihc-benefit"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1322712919.jpg')}
        >
          <h3 className="section-heading">Immune Health, Simplified</h3>
          <IconList>
            <IconListItem>Listen any time on {variant === 'ad' ? 'the' : 'your'} phone or computer.</IconListItem>
            <IconListItem>Read the written transcripts.</IconListItem>
            <IconListItem>Make delicious, immune-healthy meals.</IconListItem>
            <IconListItem>Learn from trusted sources.</IconListItem>
            <IconListItem>Enjoy unlimited access.</IconListItem>
            {variant === 'ad' ? null : <IconListItem>Go at your own pace.</IconListItem>}
          </IconList>
        </SectionImage>

        <SectionImage
          id="ihc-about-1"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1157237485.jpg')}
        >
          <h4 className="section-heading">
            {variant === 'ad' ? 'Learn the latest science' : 'Get the truth'} about...
          </h4>
          <IconList>
            {variant === 'ad' ? null : <IconList>Staying Safe in a Pandemic</IconList>}
            <IconListItem>Diet and Immune Health</IconListItem>
            <IconListItem>Soy Products</IconListItem>
            <IconListItem>
              {variant === 'ad' ? 'Lowering Risk' : 'Lowering Your Risk and Improving Your Life'}
            </IconListItem>
            <IconListItem>What Genetics Have to Do With It</IconListItem>
            <IconListItem>Alcohol and Immune Health</IconListItem>
            <IconListItem>Common Nutrition Mistakes</IconListItem>
            <IconListItem>Which Foods Lower Risk for Mortality</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="ihc-about-2"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1142910868.jpg')}
        >
          <IconList>
            <IconListItem>The Most Beneficial Supplements to Consider</IconListItem>
            <IconListItem>
              Lowering Rates of {variant === 'ad' ? 'Disease' : 'Heart Disease, Diabetes, and Dementia'}
            </IconListItem>
            <IconListItem>Reversing The Aging Process</IconListItem>
            <IconListItem>Green Tea and Immune Health</IconListItem>
            <IconListItem>Trace Minerals, Antioxidants, and Phytochemicals</IconListItem>
            <IconListItem>Sugar and Immune Health</IconListItem>
            <IconListItem>Protein Sources</IconListItem>
            <IconListItem>The Power of Prevention</IconListItem>
          </IconList>
        </SectionImage>

        <Section id="ihc-teachers" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">{variant === 'ad' ? 'The' : 'Your'} teachers…</h2>
                {variant === 'ad' ? <CollectionSpeaker id="ihcAd" /> : <CollectionSpeaker id="ihc" />}
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="ihc-cta" color="gradient-indigo-purple-right">
          <Container>
            <Row>
              <Col>
                <SalesCtaBox />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="ihc-guarantee" color="light-gray">
          <Container>
            <Row>
              <Col>
                <GuaranteeBox
                  title={
                    <>
                      {variant === 'ad' ? null : 'You’re '}Protected By Our 60-Day, Unconditional, Money-Back Guarantee.
                    </>
                  }
                >
                  {variant === 'ad' ? (
                    <>
                      <p>
                        <b>Try it out.</b> If not completely blown away, just email us at{' '}
                        <a href="mailto:support@foodrevolution.org">support@foodrevolution.org</a> any time within 60
                        days after purchase, for a prompt and courteous refund.
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        <b>Try it out.</b> If you aren't completely blown away (even if you just don’t like it), just
                        email us at <a href="mailto:support@foodrevolution.org">support@foodrevolution.org</a> any time
                        within 60 days after purchase, for a prompt and courteous refund.
                      </p>
                      <p>
                        This means you can even listen to everything, take in every lesson, and make every single
                        mouth-watering recipe — and if it doesn’t rock your world, you can still get every penny back.
                      </p>
                      <p>That’s how confident we are that you’ll LOVE The Immune Health Collection!</p>
                      <p>
                        PLUS, if for any reason you request a refund, the entire Immune Health Collection — everything
                        that you’ve downloaded, and everything you’ve learned — is still yours to keep.
                      </p>
                      <p>That’s why we call our guarantee “better than money back.”</p>
                    </>
                  )}
                </GuaranteeBox>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="ihc-learn"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/891026540.jpg')}
        >
          <h2 className="section-heading">
            {variant === 'ad' ? (
              <>
                The Immune Health <br className="d-none d-xl-block" />
                Collection Includes:
              </>
            ) : (
              <>More Of What You’ll Learn:</>
            )}
          </h2>
          <IconList>
            <IconListItem>
              Six must-have immune health foods to include{variant === 'ad' ? null : ' in your diet'} every day.
            </IconListItem>
            <IconListItem>
              Seven key nutrients that even healthy eaters miss
              {variant === 'ad' ? null : ', and how to make sure you have your bases covered'}.
            </IconListItem>
            <IconListItem>Which type of protein is associated with an increased risk of death.</IconListItem>
            <IconListItem>
              Simple steps to eliminate immune risks{variant === 'ad' ? null : '  from your life'}.
            </IconListItem>
            <IconListItem>Which three types of people need to avoid intermittent fasting.</IconListItem>
            <IconListItem>The biggest thing that most people who live vibrantly past 70 have in common.</IconListItem>
            <IconListItem>
              What we can do today to avoid future pandemics (even ones that start half a world away).
            </IconListItem>
            <IconListItem>
              Five lifestyle pillars that may prevent up to 80% of chronic illnesses
              {variant === 'ad' ? null : ', including cancer, heart disease, and Type 2 diabetes'}.
            </IconListItem>
            <IconListItem>
              Which spices are shown to support a healthy immune system (based on dozens of peer-reviewed studies), and
              much more.
            </IconListItem>
          </IconList>
        </SectionImage>

        {variant === 'ad' ? null : (
          <SectionImage
            id="ihc-foryou"
            color="light-gray"
            imgPosition="left"
            imgUrl={require('static/backgrounds/450016927.jpg')}
          >
            <h2 className="section-heading">Who The Immune Health Collection Is For</h2>
            <p>You want to do more than just leave your health up to chance.</p>
            <p>You’re open to new ideas that can maximize how good you feel every day.</p>
            <p>You want to help others in your family and community be as healthy as possible.</p>
            <p>You’re ready to stop buying the myth that what happens to us is out of our control.</p>
            <h5 className="mt-0">AND</h5>
            <p>You believe that all-natural foods have the power to radically improve the way you feel every day.</p>
          </SectionImage>
        )}

        <SectionImage
          id="ihc-protecting"
          color={variant === 'ad' ? 'light-gray' : 'white'}
          imgPosition={variant === 'ad' ? 'left' : 'right'}
          imgUrl={require('static/backgrounds/1336363998.jpg')}
        >
          <h2 className="section-heading">{variant === 'ad' ? null : 'Your '}Health Is Worth Protecting</h2>
          <p>Imagine…</p>
          <p>Knowing exactly what to eat, based on what hard scientific studies show us…</p>
          <p>Ending the confusion, and never falling prey to fads…</p>
          <p>Enjoying energy to do what’s important{variant === 'ad' ? null : ' to you, with the people you love'}…</p>
          <p>Cooking meals that deeply nourish every cell…</p>
          {variant === 'ad' ? null : <p>Putting the power in your own hands…</p>}
          <p>
            Knowing the most current facts, and easily putting {variant === 'ad' ? 'it' : 'what you know'} into action…
          </p>
          <p>
            Living a long, healthy, happy life — without fear {variant === 'ad' ? 'of' : 'that you’re'} missing
            important information{variant === 'ad' ? null : ' that’s vital to your health'}…
          </p>
          <p>Helping{variant === 'ad' ? null : ' your'} family make the best food and lifestyle decisions…</p>
        </SectionImage>

        <SectionImage
          id="ihc-future"
          color={variant === 'ad' ? 'white' : 'light-gray'}
          imgPosition={variant === 'ad' ? 'right' : 'left'}
          imgUrl={require('static/backgrounds/1142166550.jpg')}
        >
          {variant === 'ad' ? (
            <>
              <h3 className="section-heading">
                It’s time to adopt healthy habits for the longest, happiest life possible.
              </h3>
              <h4 className="text-400 mt-0">Start Today.</h4>
            </>
          ) : (
            <>
              <h3 className="section-heading">Ten years from now, will you have the vibrant health you want?</h3>
              <p>
                The Immune Health Collection will open your eyes and change the way you think, so you can adopt the
                healthy habits you need for the longest, happiest life possible.
              </p>
              <p>
                You deserve the latest research-backed information about what actually works to help your body
                effectively prevent illness, fend off pathogens, and perform at its best.
              </p>
            </>
          )}
        </SectionImage>

        <Section id="ihc-faq" color={variant === 'ad' ? 'light-gray' : 'white'}>
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Frequently Asked Questions</h3>
                {variant === 'ad' ? <FaqList list={faqIHCAd} /> : <FaqList list={faqIHC} />}
              </Col>
            </Row>
          </Container>
        </Section>

        {variant === 'ad' ? null : (
          <SectionImage
            id="ihc-everyday"
            color="light-gray"
            imgPosition="right"
            imgUrl={require('static/backgrounds/1327703255.jpg')}
          >
            <h2 className="section-heading">You eat every day, so make sure you’re not missing anything!</h2>
            <p>
              Every person has the ability to strengthen their immune system with natural food and a few easy lifestyle
              shifts.
            </p>
            <p>
              You don’t have to eat “perfectly” 100% of the time — but many people feel a significant difference in
              their energy, sleep, and happiness level when they eat more immune-focused foods.
            </p>
            <p>Pretty soon, your taste buds start to change, which means no more falling off the wagon.</p>
            <p>
              The most important thing is to stop living in fear and to give your body the natural, powerful,
              immune-strengthening foods that it loves.
            </p>
          </SectionImage>
        )}

        <Section id="ihc-cta-2" color="gradient-indigo-purple-right">
          <Container>
            <Row>
              <Col>
                <SalesCtaBox />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="ihc-resources" color="light-gray" className="section-angle-last" angle="none">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Resources:</h3>

                <ul className="list-references list-group text-small mb-0">
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[1]</div>
                    <div>https://www.fda.gov.tw/upload/189/Content/2014012817144225758.pdf</div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[2]</div>
                    <div>https://foodrevolution.org/blog/tea-health-benefits/</div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[3]</div>
                    <div>
                      https://www.telegraph.co.uk/news/health/news/4310418/Three-cups-of-tea-can-cut-breast-cancer-risk-by-a-third.html
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[4]</div>
                    <div>https://pubmed.ncbi.nlm.nih.gov/23340963/</div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[5]</div>
                    <div>
                      1 Cui L, Li Z, Chang X, Cong G, Hao L. Quercetin attenuates vascular calcification by inhibiting
                      oxidative stress and mitochondrial fission. <br className="d-none d-xl-block" />
                      Vascul Pharmacol. 2017 Jan;88:21-29. doi: 10.1016/j.vph.2016.11.006.
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[6]</div>
                    <div>
                      2 Seo DY, Lee SR, Heo JW, No MH, Rhee BD, Ko KS, Kwak HB, Han J. Ursolic acid in health and
                      disease. <br className="d-none d-xl-block" />
                      Korean J Physiol Pharmacol. 2018 May;22(3):235-248. doi: 10.4196/kjpp.2018.22.3.235. Epub 2018 Apr
                      25.
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[7]</div>
                    <div>https://onlinelibrary.wiley.com/doi/abs/10.1002/ptr.1222</div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[8]</div>
                    <div>http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.559.9875&rep=rep1&type=pdf</div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[9]</div>
                    <div>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2870528/</div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[10]</div>
                    <div>
                      https://www.pharmacytimes.com/view/vitamin-d-helps-the-immune-system-during-cold-and-flu-season
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>

      <div className="footer-content mt-auto">
        <Footer />
      </div>
      {noExitIntent ? null : <ModalExitImmunity version={!salesFullPrice ? 'salesPrice' : 'fullPrice'} />}
    </div>
  );
}
