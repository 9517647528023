import React, {Component} from 'react';
import {Col, Container, Row, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink} from 'reactstrap';
import classNames from 'classnames';
import './Header.scss';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <header className={classNames(`header ${this.props.className}`)} role="banner">
        <Container className="py-2">
          <Navbar className={classNames(`p-0 navbar-${this.props.style}`)} expand="lg">
            <NavbarBrand className="p-0 m-0">
              <div className="logo-img">
                <img
                  className="logo-img-frn"
                  src="https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-white.svg"
                />
              </div>
            </NavbarBrand>

            <NavbarToggler onClick={this.toggle} />

            <Collapse className="mt-3 mt-lg-0" isOpen={this.state.isOpen} navbar>
              <Nav navbar>
                <NavItem>
                  <NavLink href="https://support.foodrevolution.org/" target="_blank">
                    Support
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://community.foodrevolution.org/" target="_blank">
                    Login
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </Container>
      </header>
    );
  }
}

Header.defaultProps = {
  className: ''
};

export const withHeader = (BaseComponent) => (props) =>
  (
    <div>
      <Header />
      <BaseComponent {...props} />
    </div>
  );
