import React, {useEffect} from 'react';
import {Col, Container, Input, Row} from 'reactstrap';
import classNames from 'classnames';
import {Link} from 'react-scroll';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import track from 'utils/track';
import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section, {SectionImage} from 'components/Elements/Section';
import Video from 'components/Video';
import CountdownTimer from 'components/Elements/CountdownTimer';
import CheckoutButton from 'components/CheckoutButton';
import {BhcSales47, BhcSales97, SalesDisclaimer} from 'components/SalesCta';
import Footer from 'components/Elements/Footer';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {faExclamationTriangle, faQuestionCircle, faTimes} from '@fortawesome/pro-light-svg-icons';
import {ImgBulletCard, ImgCard} from 'components/Elements/ImgBullet';
import {collection} from 'data/thc.json';
import {CollectionRecipes, CollectionSpeaker} from 'components/Thc/CheckoutSelection';
import GuaranteeBox from 'components/Guarantee';
import FaqList from 'components/FaqList';
import {faqBHC} from 'data/faq.json';
import ModalExitBrain from 'components/Modals/ModalExitBrain';
import {TestimonialBubble} from 'components/Testimonial';

export default function BrainSalesIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = '',
  ctaNoTimer = false,
  variant = null,
  pageTitle = 'Get the Brain Health Collection today!',
  pageDescription = 'Get unlimited access to interviews with brain health experts, health-promoting recipes, and more!',
  shareUrl = 'https://health.foodrevolution.org/brain/join/',
  shareImg = 'https://cdn.foodrevolution.org/thc/og/b-bhc-open_graph-facebook-v20220210_v2.png',
  facebookDescription = 'Brain fog, forgetfulness, and lack of focus? There IS an answer, and it’s 100% natural. Discover the best food and lifestyle tips for a healthy brain. Learn more here:',
  twitterDescription = 'Brain fog, forgetfulness, and lack of focus? There IS an answer, and it’s 100% natural. Discover the best food and lifestyle tips for a healthy brain. Learn more here:',
  twitterImg = 'https://cdn.foodrevolution.org/thc/og/b-bhc-open_graph-twitter-v20220210_v2.png',
  pinterestDescription = 'Brain fog, forgetfulness, and lack of focus? There IS an answer, and it’s 100% natural. Research tells us exactly what to eat and do to keep a healthy brain. Learn more here:',
  pinterestImage = 'https://cdn.foodrevolution.org/thc/og/b-bhc-open_graph-pinterest-v20220210_v2.png',
  ...props
}) {
  const {
    phases: {
      brain: brainSchedule = {
        phase: 'before',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  const salesFullPrice = !ctaOverride || ctaOverride === 'fullPrice';
  const salesEnd = new Date(brainSchedule.salesEnd).getTime();

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'B-BHC',
      sku: 'B-BHC',
      name: 'Brain Health Collection',
      variant: !salesFullPrice ? 'bhc-special' : 'bhc',
      value: !salesFullPrice ? 47 : 97,
      currency: 'usd'
    });
  }, [salesFullPrice]);

  function BrainCheckout({
    iconColor = 'green',
    scrollTo = 'bhc-cta',
    btnText = 'Start Now',
    timerClass = null,
    className = null,
    ...props
  }) {
    return (
      <div className={classNames(`checkout-wrap`, className)}>
        <h2 className="section-heading mb-3">
          {!salesFullPrice ? (
            <>
              Right Now Just <del>$97</del> $47
            </>
          ) : (
            <>Right Now $97</>
          )}
        </h2>

        {ctaNoTimer || salesFullPrice ? null : (
          <>
            <div className="cta-timer mb-3">
              <div className="offer-box-timer-text">Act now to save $50. The sale expires in:</div>
              <CountdownTimer className={timerClass} time={salesEnd} />
            </div>
          </>
        )}
        {variant === 'ad' ? (
          <h5 className="mt-0">Special very limited time offer: Get it now for a 50% savings!</h5>
        ) : null}

        <CheckoutButton
          sku="B-BHC"
          price={!salesFullPrice ? '47' : '97'}
          slug={!salesFullPrice ? 'bhc-special' : 'bhc'}
        >
          Start Now
        </CheckoutButton>
        <SalesDisclaimer iconColor={iconColor} />
      </div>
    );
  }

  function SalesCtaBox(props) {
    return (
      <>
        {!salesFullPrice ? (
          <>
            {ctaNoTimer ? (
              <BhcSales47 {...props} btnText="Start Now" />
            ) : (
              <BhcSales47 timerText="Your 50% discount expires in:" date={salesEnd} {...props} btnText="Start Now" />
            )}
          </>
        ) : (
          <BhcSales97 {...props} btnText="Start Now" />
        )}
      </>
    );
  }

  return (
    <div className="page page-nosocial d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={shareImg} />
      <div className="page-content">
        <Header
          className="background-purple header-wrap"
          logoWhite
          style="dark"
          pageDescription={pageDescription}
          shareUrl={shareUrl}
          shareImg={shareImg}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterImg={twitterImg}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
        />

        <Section id="bhc-header" color="black" className="section-angle-first" innerClass="text-white text-center">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">
                  You deserve a healthy <br className="d-none d-sm-block d-lg-none" />
                  brain and clear mind
                </h2>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <Video
                  url={
                    !salesFullPrice
                      ? 'https://www.youtube.com/watch?v=QsHtO7dmlHU'
                      : 'https://www.youtube.com/watch?v=0xLTO_nno9g'
                  }
                  playing
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="mt-4 mb-3">
                  Take Control of Your Health <br className="d-none d-xl-block" />
                  with the Brain Health Collection.
                </h3>
                <p>
                  Keep yourself free from dementia, Alzheimer’s, depression, anxiety,{' '}
                  <br className="d-none d-lg-block" />
                  and more — with intelligent food and lifestyle choices anyone can make.
                </p>
                <BrainCheckout iconColor="white" />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="bhc-tired"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1179463668.jpg')}
        >
          <h2 className="section-heading">
            Most people — heck, most doctors — believe these <br className="d-none d-xl-block" />
            brain myths…
          </h2>
          <p>They think that… As you get older, your brain health naturally declines.</p>
          <p>
            Alzheimer’s is a normal part of aging, and if you’re going to get it, there’s nothing you can do to prevent
            it.
          </p>
          <p>
            You can do everything right — eat clean, exercise, get good sleep — and if your genetics are bad, you’re
            going to end up losing your memory, your ability to function, and the very essence of who you are.
          </p>
          <p>
            If you’ve got depression, ADHD, or other mental health challenges, the only thing that may help is
            medication. You can’t overcome them by changing your habits.
          </p>
        </SectionImage>

        <SectionImage
          id="bhc-research"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1297589734.jpg')}
        >
          <h2 className="section-heading">
            Not one of those myths is <br className="d-none d-xl-block" />
            even remotely true!
          </h2>
          <p>
            The most rigorous scientific research shows that you have a lot of control over how well your brain
            functions, now and in the future.
          </p>
          <p>You don’t have to be a helpless victim of encroaching dementia.</p>
          <p>
            By changing a few habits, you can all but eliminate the risk of developing Alzheimer’s — even if you have
            some of the “bad” <br className="d-none d-xl-block" />
            genes that supposedly mark you as a definite victim.
          </p>
          <p>
            Yet most people <b>won’t</b> hear this at the doctor’s office. <br className="d-none d-xl-block" />
            Much of the research is so new, it hasn’t had time to impact <br className="d-none d-xl-block" />
            medical school curricula.
          </p>
          <p>Meanwhile…</p>
          <p>
            <b>
              Alzheimer’s is already the third leading cause of death in the United States — and cases of cognitive
              decline are predicted to triple in the coming decades.
            </b>
          </p>
          <p>
            <b>And up to 90% of these cases are preventable.</b>
          </p>
          <p>
            Because they’re mostly caused by eating harmful foods. Dangerous foods that have been shown to cause brain
            inflammation, depression, dementia, ADHD, and other brain problems.
          </p>
          <p>
            If you’re confused about what to eat and do to keep your brain healthy, mind clear, and memories intact,
            you’re not alone.
          </p>
        </SectionImage>

        <SectionImage
          id="bhc-difference"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1289084512.jpg')}
        >
          <h2 className="section-heading mb-3">You can make a difference.</h2>
          <h2 className="mt-0">
            Your brain is <br className="d-none d-lg-block d-xl-none" />
            worth protecting.
          </h2>
          <p>
            <b>
              Every single day we make countless choices that either bring us closer to a sharp, focused, healthy mind —
              or move us further away.
            </b>
          </p>
          <p>Keeping your brain healthy requires YOU to think differently about what you eat and do each day.</p>
        </SectionImage>

        <Section id="bhc-studies" color="light-gray">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10">
                <h2 className="section-heading text-center mb-5">
                  Discover What <br className="d-none d-sm-block d-md-none" />
                  Clinical Studies Say
                </h2>
                <ImgCard img={require('static/backgrounds/1319888855.jpg')}>
                  A study found that regular consumption of a certain <br className="d-none d-xl-block" />
                  beverage could be associated with up <br className="d-none d-lg-block d-xl-none" />
                  to a 65% reduction <br className="d-none d-xl-block" />
                  in dementia risk.<sup>1</sup>
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1216094652.jpg')}>
                  One study found that people who <br className="d-none d-md-block d-xl-none" />
                  eat a particular diet reduced their risk of Alzheimer's disease by 53%.
                  <sup>2</sup>
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1305332035-wide.jpg')}>
                  An award-winning American Heart <br className="d-none d-md-block d-lg-none" />
                  Association study <br className="d-none d-xl-block" />
                  followed one hundred and thirty thousand people and found that it’s possible to reduce the risk of
                  stroke by 44% with <br className="d-none d-xl-block" />
                  certain simple choices.<sup>3</sup>
                </ImgCard>
                <ImgCard img={require('static/backgrounds/544600984.jpg')}>
                  A study recently showed that a brisk walk can reduce <br className="d-none d-xl-block" />
                  your chances of Alzheimer's by 43%.<sup>4</sup>
                </ImgCard>
                <h3 className="mt-4 text-center">That’s very good news.</h3>
                <p className="text-center">
                  It means that with the RIGHT information, food, <br className="d-none d-sm-block" />
                  and actions, you can beat the odds and change your life.
                </p>
                <p className="text-center">
                  But even smart, educated people who eat healthy most <br className="d-none d-xl-block" />
                  of the time aren’t always getting the nutrients they need.
                </p>
                <p className="text-center">(It’s not enough just to avoid fast food and soda.)</p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="bhc-questions-1"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1328365317.jpg')}
        >
          <h2 className="section-heading">Questions persist like…</h2>
          <IconList iconColor="purple">
            <IconListItem icon={faQuestionCircle}>
              What actually causes Alzheimer’s and other <br className="d-none d-sm-block d-md-none d-xl-block" />
              neurological disorders?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>
              What’s the best brain health diet? Paleo, intermittent{' '}
              <br className="d-none d-sm-block d-lg-none d-xl-block" />
              fasting, keto, vegan?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>
              Is there anything I can do to grow <br className="d-none d-lg-block d-xl-none" />
              new brain cells?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>What can I do to stay mentally fit after retirement?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              Is epigenetics real? Can I really flip certain <br className="d-none d-lg-block d-xl-none" />
              genes on or off?
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="bhc-questions-2"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/177551231.jpg')}
        >
          <IconList iconColor="purple">
            <IconListItem icon={faQuestionCircle}>What should I never eat?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              What type of exercises are best for keeping <br className="d-none d-lg-block d-xl-none" />
              my brain healthy?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>
              Are there specific supplements that can <br className="d-none d-xl-block" />
              optimize brain function?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>Should I cut out coffee and alcohol?</IconListItem>
            <IconListItem icon={faQuestionCircle}>How do I get my family on board?</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="bhc-truth"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1293887654.jpg')}
        >
          <h3 className="section-heading">
            The truth is that most brain diseases <br className="d-none d-md-block d-lg-none d-xl-block" />
            can be avoided with healthy food and <br className="d-none d-md-block d-lg-none d-xl-block" />
            simple <br className="d-none d-lg-block d-xl-none" />
            lifestyle shifts.
          </h3>
          <p>
            Hundreds of thousands of people have improved their brain health naturally{' '}
            <br className="d-none d-md-block d-lg-none" />— often shocking doctors who said it couldn’t be done.
          </p>
          <p>
            This information is available right now, which is why we’ve brought{' '}
            <br className="d-none d-md-block d-lg-none" />
            together top brain health experts to teach you what to eat and do for{' '}
            <br className="d-none d-md-block d-lg-none" />
            long-lasting brain health.
          </p>
        </SectionImage>

        <Section id="bhc-intro" color="white">
          <Container>
            <Row className="text-center">
              <Col>
                <h3 className="section-heading">
                  YOUR BRAIN <i>WANTS</i> TO BE WELL
                </h3>
                <p>Give it what it needs to thrive.</p>
                <p className="mt-4 mb-2">
                  <i>Introducing the</i>
                </p>
                <img
                  className="mb-4"
                  src="https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-color.svg"
                  style={{maxHeight: '75px'}}
                />
                <h2 className="mt-0 mb-4">Brain Health Collection</h2>
                <Row className="justify-content-center">
                  <Col xs="10" lg="7">
                    <img src="https://cdn.foodrevolution.org/thc/bhc-product-image.png" />
                  </Col>
                </Row>
                <p>
                  Everything you need to put your brain health <br className="d-none d-md-block d-lg-none" />
                  in your hands — where it belongs.
                </p>
                <BrainCheckout className="mt-5" btnText="Get Started" timerClass="countdown-dark" />
              </Col>
            </Row>
            <Row className="justify-content-center text-h5 mt-5 pt-5">
              <Col lg="10">
                <ImgCard img={require('static/backgrounds/1316272527.jpg')}>
                  Eat the ultimate brain health diet.
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1191287609.jpg')}>Sleep better & eliminate stress.</ImgCard>
                <ImgCard img={require('static/backgrounds/1344526137.jpg')}>
                  Understand what the latest scientific <br className="d-none d-xl-block" />
                  evidence recommends.
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1191762016.jpg')}>Keep memory power sharp.</ImgCard>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="bhc-testimonials" color="light-gray">
          <Container>
            <Row>
              <Col>
                <TestimonialBubble
                  id={variant === 'ad' ? 'stephanie-s-ad' : 'stephanie-s'}
                  bgColor="white"
                  className="mb-5"
                />
                <TestimonialBubble id={variant === 'ad' ? 'bonny-ad' : 'bonny'} bgColor="white" className="mb-5" />
                <TestimonialBubble id="cynthia-s" bgColor="white" />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="bhc-inside" color="white">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h2 className="section-heading text-center">
                  What’s Inside The <br className="d-none d-sm-block" />
                  Brain Health Collection
                </h2>
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/thc/bhc-speakers.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">4 Interviews With Brain Health Experts</h4>
                    <p>
                      In this one-of-a-kind collection, you get exclusive interviews with four leading brain health
                      experts, including <i>New York Times</i> best-selling authors, leading doctors, and award-winning
                      researchers. (Three of the interviews are audio-only, conducted by 2 million-copy bestselling
                      author John Robbins, and the fourth is video and audio and is conducted by Food Revolution Network
                      CEO Ocean Robbins.)
                    </p>
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/thc/bhc-transcripts.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">Written Transcripts</h4>
                    <p>
                      No need to write everything down — we’ve done it for you. Each interview is fully transcribed so
                      you can read through the material quickly, highlight important sections, and download it to your
                      computer to revisit anytime. (The transcripts even include source links to key studies cited, so
                      you can check the facts and dive deeper if you want.)
                    </p>
                  </Col>
                </Row>
                <hr className="my-5" />
                <h4 className="section-heading">6 Recipes Your Brain Will Love</h4>
                <p>
                  The scientific studies are in, and it’s very good news — eating the right foods can be one of the best
                  things you do for your brain. This crowd-pleasing collection of recipes are quick, easy, DELICIOUS…
                  and they provide maximum nourishment for your brain cells.
                </p>
                <CollectionRecipes id="bhc" className="mt-4" />
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/global/guidebook/brain-food.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">
                      <i>Brain Food: 8 Superfoods Your Brain Will Love Guidebook</i>
                    </h4>
                    <p>
                      How do you avoid becoming another dementia statistic? We’ve put together a list of 8 inexpensive
                      superfoods that research has shown to provide critical minerals, vitamins, antioxidants, and
                      micronutrients that can help keep your brain healthy.
                    </p>
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/global/guidebook/bhc-workbook.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">Brain Health Workbook</h4>
                    <p>
                      Learning what to do is the first step — but remembering it and then putting it into action is even{' '}
                      <i>more</i> important. We designed this comprehensive workbook to help you retain and maximize
                      everything you learn from the Brain Health Collection. Test your knowledge, take notes, and absorb
                      the information quickly as you follow along.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="bhc-benefit"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1322712919.jpg')}
        >
          <h3 className="section-heading">Brain Health, Simplified</h3>
          <IconList>
            <IconListItem>Listen any time on your phone or computer.</IconListItem>
            <IconListItem>Read the written transcripts.</IconListItem>
            <IconListItem>Make delicious, immune-healthy meals.</IconListItem>
            <IconListItem>Learn from trusted sources.</IconListItem>
            <IconListItem>Enjoy unlimited access.</IconListItem>
            <IconListItem>Go at your own pace.</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="bhc-about-1"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1365705067.jpg')}
        >
          <h4 className="section-heading">You’ll Discover:</h4>
          <IconList>
            <IconListItem>The surprising link between diet and attention problems</IconListItem>
            <IconListItem>What foods to avoid if you're struggling with depression</IconListItem>
            <IconListItem>What you should feed your gut bacteria to improve your mood</IconListItem>
            <IconListItem>
              What we do and don't know about the effects of CBD <br className="d-none d-xl-block" />
              on brain neurotransmitters
            </IconListItem>
            <IconListItem>
              Whether drinking coffee increases or decreases your risk <br className="d-none d-xl-block" />
              of developing Alzheimer's
            </IconListItem>
            <IconListItem>
              What types of exercise are most effective in protecting <br className="d-none d-xl-block" />
              brain health
            </IconListItem>
            <IconListItem>Why Alzheimer's isn't a disease of aging</IconListItem>
            <IconListItem>
              How you can slow or accelerate cognitive decline with <br className="d-none d-xl-block" />
              every bite of food
            </IconListItem>
            <IconListItem>
              The revolutionary discovery that amyloid plaque isn't the problem -- it's the brain's *response* to the
              problem
            </IconListItem>
            <IconListItem>
              The "stealth" biomarker that most doctors don't test for that's strongly linked to Alzheimer's
            </IconListItem>
            <IconListItem>Why you may be doing B12 supplementation wrong</IconListItem>
            <IconListItem>
              Why your "normal" blood levels may still be setting <br className="d-none d-xl-block" />
              you up for dementia
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="bhc-about-2"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1346181098.jpg')}
        >
          <IconList>
            <IconListItem>How gum health influences brain health?</IconListItem>
            <IconListItem>
              The free diagnostic tests you can take online to assess <br className="d-none d-xl-block" />
              your cognitive status
            </IconListItem>
            <IconListItem>
              Why drinking cow's milk can contribute to anxiety <br className="d-none d-xl-block" />
              and depression
            </IconListItem>
            <IconListItem>
              How 100,000 brain scans led to the discovery <br className="d-none d-xl-block" />
              of "Dinosaur Syndrome"
            </IconListItem>
            <IconListItem>
              How to improve your brain health right now by reverse engineering the "evil ruler" strategy
            </IconListItem>
            <IconListItem>
              Whether forgetting where you parked signals the onset <br className="d-none d-xl-block" />
              of cognitive decline
            </IconListItem>
            <IconListItem>The two types of foods that harm the brain the most</IconListItem>
            <IconListItem>
              The two kinds of iron -- and why one is much safer for your brain than the other
            </IconListItem>
            <IconListItem>
              When you hear this statistic, you'll want to take a 20-minute brisk walk every day
            </IconListItem>
            <IconListItem>How squats and lunges can grow your... brain!</IconListItem>
            <IconListItem>Simple hacks for better sleep -- and a more resilient brain</IconListItem>
            <IconListItem>
              A common household spice that increases the anti-inflammatory bioavailability of turmeric by 2000%
            </IconListItem>
          </IconList>
        </SectionImage>

        <Section id="bhc-teachers" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">Your Teachers…</h2>
                <CollectionSpeaker id="bhc" />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="ihc-cta" color="gradient-indigo-purple-right">
          <Container>
            <Row>
              <Col>
                <SalesCtaBox />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="bhc-learn-1"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1193433604.jpg')}
        >
          <h2 className="section-heading">More Of What You’ll Learn:</h2>
          <IconList>
            <IconListItem>
              How your lifestyle choices impact your memory <br className="d-none d-sm-block d-md-none d-xl-block" />
              and mental clarity.
            </IconListItem>
            <IconListItem>
              The five most brain-healthy foods and the five most{' '}
              <br className="d-none d-sm-block d-lg-none d-xl-block" />
              brain-dangerous foods.
            </IconListItem>
            <IconListItem>
              The type of protein that can lead to brain inflammation and to neurodegenerative disease.
            </IconListItem>
            <IconListItem>
              Why what you may have been taught in high school biology about genetics was only a partial truth — and how
              to turn unwanted genes off.
            </IconListItem>
            <IconListItem>
              What people who took a lot of antibiotics as children need to know about brain health{' '}
              <br className="d-none d-lg-block d-xl-none" />
              and anxiety.
            </IconListItem>
            <IconListItem>
              The surprising link between the microbiome, ADHD, and children — and the most effective options besides
              medication.
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="bhc-learn-2"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/915675116.jpg')}
        >
          <IconList>
            <IconListItem>
              Which foods to avoid to keep serotonin levels <br className="d-none d-sm-block d-md-none d-lg-block" />
              in a healthy range.
            </IconListItem>
            <IconListItem>
              The top supplements and doses to <br className="d-none d-lg-block d-xl-none" />
              optimize brain health.
            </IconListItem>
            <IconListItem>
              The 8 most potent brain health superfoods <br className="d-none d-lg-block d-xl-none" />— and which foods
              to eliminate from your <br className="d-none d-lg-block d-xl-none" />
              diet right away.{' '}
            </IconListItem>
            <IconListItem>
              What can be done if you have any of the early warning{' '}
              <br className="d-none d-sm-block d-lg-none d-xl-block" />
              signs of dementia.
            </IconListItem>
            <IconListItem>
              Which single biggest factor can make brains <br className="d-none d-sm-block d-md-none d-xl-block" />
              resistant to Alzheimer’s.
            </IconListItem>
            <IconListItem>And much, much more</IconListItem>
          </IconList>
        </SectionImage>

        <Section id="bhc-guarantee" color="light-gray">
          <Container>
            <Row>
              <Col>
                <GuaranteeBox
                  title={
                    <>
                      You’re Protected By <br className="d-none d-md-block d-lg-none" />
                      Our 60-Day, Unconditional, <br className="d-none d-md-block d-lg-none" />
                      Money-Back Guarantee.
                    </>
                  }
                >
                  <p>
                    <b>Try it out.</b> If you aren't completely blown away, even if you{' '}
                    <br className="d-none d-sm-block d-md-none" />
                    just don’t like it, just email us at{' '}
                    <a href="mailto:support@foodrevolution.org">support@foodrevolution.org</a>, any time within 60 days
                    after purchase, for a prompt and courteous refund.
                  </p>
                  <p>
                    This means you can even listen to everything, take in every lesson, and{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    make every single mouth-watering recipe, and if it doesn’t rock your world,{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    you get every penny back.
                  </p>
                  <p>
                    That’s how confident we are that you’ll LOVE The <br className="d-none d-sm-block d-lg-none" />
                    Brain Health Collection!
                  </p>
                  <p>
                    PLUS, if for any reason you request a refund, the entire Brain Health Collection{' '}
                    <br className="d-none d-lg-block d-xl-none" />— everything that you’ve downloaded, and everything
                    you’ve learned — is still <br className="d-none d-lg-block d-xl-none" />
                    yours to keep.
                  </p>
                  <p>That’s why we call our guarantee “better than money back.”</p>
                </GuaranteeBox>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="bhc-foryou"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1308292157.jpg')}
        >
          <h2 className="section-heading">
            The Brain Health Collection <br className="d-none d-xl-block" />
            is For You If…
          </h2>
          <p>
            You want to do more than just leave your health <br className="d-none d-lg-block d-xl-none" />
            up to chance;
          </p>
          <p>
            You don’t want to put your family in a position <br className="d-none d-lg-block d-xl-none" />
            where they have to <br className="d-none d-sm-block d-md-none" />
            care for you while you don’t remember their names;
          </p>
          <p>
            You’re open to new ideas that can maximize how good you{' '}
            <br className="d-none d-sm-block d-md-none d-xl-block" />
            feel every day;
          </p>
          <p>
            You want to help others in your family and community to be as{' '}
            <br className="d-none d-sm-block d-lg-none d-xl-block" />
            healthy as possible; or
          </p>
          <p>
            You believe that all-natural foods and healthy lifestyle choices should be your first and most important
            line of defense against chronic illness.
          </p>
        </SectionImage>

        <SectionImage
          id="bhc-protecting"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1304164659.jpg')}
        >
          <h2 className="section-heading">
            Your Brain Is <br className="d-none d-lg-block d-xl-none" />
            Worth Protecting
          </h2>
          <p>
            Imagine knowing exactly what to eat, based on what the best <br className="d-none d-xl-block" />
            peer-reviewed scientific studies show us.
          </p>
          <p>Ending the confusion — and never falling prey to fads.</p>
          <p>
            Enjoying mental clarity and energy to do what’s important to you, <br className="d-none d-xl-block" />
            with the people you love.
          </p>
          <p>
            Cooking meals that deeply nourish every cell, instead of ones that only{' '}
            <br className="d-none d-md-block d-lg-none" />
            fill up your stomach.
          </p>
          <p>
            Recovering from brain disease, despite being told that odds of{' '}
            <br className="d-none d-sm-block d-lg-none" />
            recovery were poor.
          </p>
          <p>Easily putting what you know into action.</p>
          <p>
            Living a long, healthy, happy life — without fear that you’re <br className="d-none d-md-block d-lg-none" />
            missing important information.
          </p>
          <p>
            Helping your family make the best food and lifestyle decisions so you can truly enjoy your time together.
          </p>
        </SectionImage>
        <SectionImage
          id="bhc-future"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/524817079.jpg')}
        >
          <h2 className="section-heading">YOUR HEALTH IS #1.</h2>
          <p>
            Ten years from now, will you have the vibrant <br className="d-none d-lg-block d-xl-none" />
            health you want?
          </p>
          <p>
            Or will you look back on your life and wish you’d <br className="d-none d-lg-block d-xl-none" />
            taken action sooner?
          </p>
          <p>
            The Brain Health Collection will open your eyes and change <br className="d-none d-md-block d-lg-none" />
            the way you think — so you can adopt healthy habits you need for{' '}
            <br className="d-none d-md-block d-lg-none" />
            the longest, happiest life possible.
          </p>
          <p>We hope you’ll join us!</p>
        </SectionImage>
        <Section id="bhc-faq" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Frequently Asked Questions</h3>
                <FaqList list={faqBHC} />
              </Col>
            </Row>
          </Container>
        </Section>
        <SectionImage
          id="bhc-everyday"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1352439474.jpg')}
        >
          <h2 className="section-heading">
            You eat every day. <br className="d-none d-sm-block d-md-none d-lg-block" />
            So make it count!
          </h2>
          <p>
            Every person has the ability to reduce their risk significantly with natural food and a few easy{' '}
            <br className="d-none d-lg-block d-xl-none" />
            lifestyle shifts.
          </p>
          <p>
            You don’t have to eat “perfectly” 100% of the time <br className="d-none d-lg-block d-xl-none" />— but most
            people feel the difference in their energy, productivity, sleep, and happiness level when they eat more
            foods for brain health and adopt the best <br className="d-none d-lg-block d-xl-none" />
            lifestyle practices.
          </p>
          <p>
            Pretty soon, your taste buds change and it’s not <br className="d-none d-lg-block d-xl-none" />
            unusual to start craving greens instead of junk food.
          </p>
          <p>
            The most important thing is to stop living in fear <br className="d-none d-lg-block d-xl-none" />
            and to give your brain the natural, powerful, healthy foods it loves.
          </p>
        </SectionImage>

        <Section id="bhc-cta-2" color="gradient-indigo-purple-right">
          <Container>
            <Row>
              <Col>
                <SalesCtaBox trees />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="bhc-resources" color="light-gray" className="section-angle-last" angle="none">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Resources:</h3>

                <ul className="list-references list-group text-small mb-0">
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[1]</div>
                    <div>
                      Eskelinen MH, Kivipelto M. Caffeine as a protective factor in dementia and Alzheimer's disease.{' '}
                      <br className="d-none d-xl-block" />
                      <i>J Alzheimers Dis.</i> 2010;20 Suppl 1:S167-74. doi: 10.3233/JAD-2010-1404.
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[2]</div>
                    <div>
                      “New MIND Diet May Significantly Protect Against Alzheimer’s Disease.” RUSH.edu.{' '}
                      <br className="d-none d-xl-block" />
                      Available from:
                      https://www.rush.edu/news/new-mind-diet-may-significantly-protect-against-alzheimers-disease
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[3]</div>
                    <div>
                      Sherzai D, Sherzai A. “A Game-Changing Solution to One of the Most Devastating Diseases of the
                      Brain: Stroke.” Nutritionstudies.org. Published 20 March 2019.{' '}
                      <br className="d-none d-xl-block" />
                      Available from:
                      https://nutritionstudies.org/a-game-changing-solution-to-one-of-the-most-devastating-diseases-of-the-brain-stroke/
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[4]</div>
                    <div>
                      Jameson, M. “Daily walk cuts dementia risk, studies show.” Vestibular.org. Published 1 Nov 2013.{' '}
                      <br className="d-none d-xl-block" />
                      Available from: https://vestibular.org/daily-walk-cuts-dementia-risk-studies-show/
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>

      <div className="footer-content mt-auto">
        <Footer />
      </div>
      {noExitIntent ? null : <ModalExitBrain version={!salesFullPrice ? 'salesPrice' : 'fullPrice'} />}
    </div>
  );
}
